/* @import url('https://fonts.googleapis.com/css2?family=Archivo:wght@400;500;600;700&amp;family=Poppins:wght@300;400;500;600;700;800;900&amp;display=swap'); */

@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@400;500;600;700&family=Poppins:wght@300;400;500;600;700;800;900&family=Bigshot+One&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.pac-container {
  background-color: #fff;
  z-index: 2001;
  position: fixed;
  display: inline-block;
  float: left;
}

.modal {
  z-index: 2000;
}

.modal-backdrop {
  z-index: 1000;
}

​ .success1 {
  background-color: lightseagreen;
}
​ .success2 {
  background-color: lightseagreen;
}

.blink_me {
  animation: blinker 1s linear infinite;
}
.blink_me2 {
  animation: blinker 0.7s linear 4;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.blink_txt {
  animation: blinker 0.8s linear infinite;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.modal-80w {
  min-width: 80%;
}

.modal-60w {
  min-width: 60%;
}

.modal-60wh {
  min-width: 595px;
  min-height: 420px;
}

.modal-50w {
  min-width: 50%;
  min-height: 50%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.nav-color {
  background-color: rgba(0, 40, 161, 0.9);
}

a#collasible-nav-dropdown.dropdown-toggle.nav-link::after {
  color: white;
}

.navbar .navbar-toggler .icon-bar {
  background: white !important;
}
