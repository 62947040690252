.my-button {
  margin-bottom: 6%;
  background-color: #1777ca;
  display: inline-block;
  border-radius: 10%;
}

.my-button:hover {
  background-color: rgb(49, 163, 74);
  color: white;
}

.my-button:focus {
  background-color: rgb(150, 37, 2);
  color: white;
}
